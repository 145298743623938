body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


:root {
  --main-green-color: #03E094;
  --main-text-color-black: #000;
  --main-text-color-grey: #7f8389;
  --main-text-color-darkgrey: #4F5256;
  --main-info-background-grey: #e5e5e5;
}

* {
  -ms-overflow-style: none;
}

*::-webkit-scrollbar {
  display: none;
}

a{
  text-decoration: none;
  color: var(--main-text-color-black);
}

a:hover{
  color: var(--main-text-color-darkgrey);
}

#root {
  display: flex;
  flex: 1;
  justify-content: center;
}

.rentalRow:hover {
  background-color: rgb(252, 252, 252) !important;
}

.formContainer {
  border: 3px solid #f1f1f1;
  width: 100%;
  height: 100%;
}

input,
button {
  border: none;
  text-decoration: none;
  outline: none !important;
}

input:focus,
textarea {
  outline: none !important;
}

button {
  width: 80%;
  height: 5vh;
  background: var(--main-green-color);
  color: #f1f1f1;
  cursor: pointer;
}

/* ********************************* Input Styling *************************** */

.inputContainer {
  display: flex;
  width: 94%;
  padding-bottom: 1.5vh;
  border-bottom: 1px solid rgba(127, 131, 137, 0.2);
  margin-bottom: 1vh;
}

.inputContainer span {
  color: var(--main-text-color-grey);
  width: 30%;
}

.inputContainer input {
  text-align: left;
  width: 70%;
}

/* ********************************** Time Picker **************************** */

.timePickerContainer {
  background: rgb(246, 246, 246);
  margin-top: 2vh;
  width: 90%;
  padding: 0px 12px;
  border-radius: 10px;
}

.timePickerContainer:hover {
  background: var(--main-green-color);
}

.timePicker {
  display: flex;
  flex-direction: row
}

.timePicker span {
  margin-top: 1.5vh;
}

.react-time-picker__button {
  width: auto !important;
  padding: 0px 4px;
}

.react-time-picker__wrapper button {
  height: auto !important;
}

.timePicker__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 10px 0;
  padding: 0px 10px;
}

.timePicker__container>*>* {
  height: 25px;
}

.timePicker__container__content {
  display: flex;
  max-width: 100%;
  flex-basis: 420px;
  flex-direction: column;
  flex-grow: 100;
  align-items: stretch;
  background-color: white;
}

.react-time-picker__inputGroup input {
  width: 0 !important;
}

/* ********************************** NavBar **************************** */

.topNavBarContainer {
  flex-direction: row;
  flex: 1 1 0%;
  position: fixed;
  background-color: var(--main-green-color);
  color: white;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 40px;
  z-index: 1;
}

.topNavBarContainer img {
  width: 30px;
  height: auto;
}

.topNavBarItem {
  cursor: pointer;
  margin: auto;
}

.topNavBarItem img {
  width: 25px;
  height: 25px;
}

.topNavBarItem.img.user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 20vh;
  width: 100%;
  margin: 0;
  margin-bottom: 3vh;
}

.topNavBarItem.img.user img {
  height: 80px;
  width: 80px;
  border: 1px solid var(--main-green-color);
  border-radius: 50%;
  margin-top: 3vh;
}

.topNavBarItem.img.logo {
  height: 20vh;
  margin-top: 28vh;
  width: auto;
}

.topNavBarItem.img.user p {
  color: var(--main-text-color-grey);
  font-weight: 800;
  font-size: 18px;
  margin: 0;
  margin-top: 1vh;
}

.sidenav img {
  width: 25px;
  height: 25px;
}

.sidenav {
  height: 100%;
  width: 15%;
  position: fixed;
  z-index: 1;
  left: 0;
  overflow-x: hidden;
  margin-top: 40px;
  background-color: #fff;
}

.sidenavItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 16%;
  font-size: 14px;
  cursor: pointer;
}

.sidenav span {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 20px;
}

.sidenav span:hover {
  color: #f1f1f1;
}

/* **********************************LOGIN**************************** */
.container_login {
  width: 100%;
  max-width: 1000px;
  height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin: auto;
}

.login_header_img {
  margin: 30px;
  width: 50%;
  height: 10vh;
}

.container_login_content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 60vh;
  margin: 50px 0;
}

.container_login_content_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  border: 1px solid #E5E5E5;
  border-radius: 10px;
  width: 35%;
  height: 90%;
  margin-right: 10%;
}

.container_login_content_form p {
  margin-bottom: 40px;
  font-size: 18px;
}

.login_input {
  width: 80%;
  height: 30px;
  margin: 20px 0;
  border-bottom: 1px solid #DBDBDB;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
}

.login_input input {
  width: 90%;
  height: 100%;
}

.login_input_icon {
  width: 20px;
  height: 20px;
  padding: 5px 10px 0 0;
}

.login_input_button {
  margin: 45px 0;
}

.login_splash_img {
  width: 50%;
  height: 100%;
}

.mainInfo {
  width: 85%;
  height: 95vh;
  margin-left: 15%;
  padding-top: 5vh;
  background: rgb(229, 229, 229);
}

.mainInfoContainer {
  width: 91%;
  height: 73vh;
  margin: 3% 5% 2.31% 5%;
  background: white;
  -ms-overflow-style: none;
  overflow: auto;
  border-radius: 15px;
}

/************************************** Table Style *******************************************/
#title {
  font-family: arial, sans-serif;
  margin-left: inherit;
}

#students {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 3px solid #ddd;
  width: 90%;
  height: 40vh;
  margin-left: inherit;
  margin-top: 5%;
  margin-bottom: 2%;
}

#students td,
#students th {
  border: 1px solid #ddd;
  padding: 8px;
}

#students tr:nth-child(even) {
  background-color: #f2f2f2;
}

#students tr:hover {
  background-color: #ddd;
}

#students th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: var(--main-green-color);
  color: white;
}

.tableHeader {
  display: flex;
  flex-direction: row;
  flex: 1 1 0%;
  justify-content: center;
  height: 60px;
  position: absolute;
  background-color: white;
  width: 75%;
  border-radius: 10px;
}

.showTableList {
  background-color: var(--main-text-color-grey);
  border: thin solid var(--main-green-color);
  padding: 1px 2px;
  cursor: pointer;
  height: 3vh;
  margin-top: 1.5vh;
  text-align: center;
  width: 10%;
  right: 5px;
  font-size: 12px;
}

.graphStyle {
  width: 100%;
  height: 69vh;
  margin-top: 5vh;
}

.csvExportButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  margin-top: 14px;
  height: 30px;
  color: white;
  font-size: 12px;
  background-color: var(--main-green-color);
  border-radius: 5px;
  cursor: pointer;
}

.csvExportButton:hover {
  width: 101px;
}

.tableHeader a {
  text-decoration: none;
}

.csvExportButton img {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

/********************************************** LOADER ****************************************/
.loader {
  border: 16px solid var(--main-info-background-grey);
  /* Light grey */
  border-top: 16px solid var(--main-green-color);
  /* Blue */
  border-radius: 50%;
  margin: auto;
  margin-top: 40vh;
  width: 100px;
  height: 100px;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/************************************** User Style *******************************************/
.userContainer {
  height: 84vh !important;
  padding-left: 3% !important;
  width: 50% !important;
  margin-left: auto !important;
  margin-bottom: 10px !important;
  margin-right: auto;
}

.userEditButton {
  display: flex;
  margin-top: 4vh;
  margin-left: 81%;
  height: 25px;
  width: 85px;
}

.userEditButton img {
  width: 20px;
  margin-right: 5%;
  border-right: 1px solid white;
  padding-right: 5%;
}

.userEditButton span {
  margin-top: 0.5vh;
}

/************************************** Cabinet Style *******************************************/

.cabinetInfoContainer {
  height: 84vh !important;
  padding-left: 3% !important;
  width: 50% !important;
  margin-left: auto !important;
  margin-right: auto;
}

.cabinetInfoUpdateButtons {
  display: flex;
  justify-content: flex-end;
  width: 94%;
}

.cabinetInfoUpdateButtons button {
  margin-top: 10px;
  margin-left: 5%;
}

.cabinetInfoEditButton {
  display: flex;
  margin-top: 4vh;
  margin-left: 70%;
  height: 25px;
  width: 155px;
}

.cabinetInfoEditButton img {
  width: 20px;
  margin-right: 5%;
  border-right: 1px solid white;
  padding-right: 5%;
}

.cabinetInfoEditButton span {
  margin-top: 0.5vh;
}

.cabinetInfoSelectTime {
  display: flex;
  margin-top: 5vh;
}

.cabinetInfoSelectTime span {
  width: 20%;
}

.cabinetInfoSelectTime select {
  border: none;
  margin-top: auto;
}

.cabinetInfoSelectTime select:hover {
  font-weight: 500;
  cursor: pointer;
  background: var(--main-green-color);
}

.venuImages{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin: 15px 5% 15px 0px;
}

.venuImages img {
  width: 90%;
  height: 150px;
}

[type="file"] {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

[type="file"] + label {
  margin: auto 0px 15px 80%;
}

[type="file"]:focus + label,
[type="file"] + label:hover {
    font-size: 14px;
}

.menuItemContainer{
  margin-left: 20px;
  display: grid;
  grid-template-columns: 46% 46%;
  grid-column-gap: 20px;
}
/************************************** Main Style *******************************************/
.mainControlButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2%;
}

.mainControlButton {
  justify-content: center;
  align-items: center;
  width: 10%;
  height: 9vh;
  border-radius: 3px;
  border: 1px solid #CED4DA;
  background: white;
  margin: 0 2.5%;
  display: flex;
  cursor: pointer;
}

.mainControlButton:hover {
  width: 9.9%;
  height: 8.9vh;
}

.mainControlButtonInfo {
  text-align: center;
  color: #4F5256;
}

.mainControlButtonInfo div:nth-child(1) {
  font-size: 24px;
  margin-bottom: 5px;
}

.mainControlButtonInfo div:nth-child(2) {
  font-size: 11px;
}

/************************************** Discount Style *******************************************/


